import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="copyright">
        <li>Copyright &copy;2025 四川红鼎石科技有限公司</li>
        <li><a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn">蜀ICP备15021620号</a></li>
      </ul>
    </div>
  </footer>
)

export default Footer
